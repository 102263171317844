<template>
  <div class="reopen">
    <el-dialog title="发票冲红重开"
               :visible.sync="dialogVisible"
               width="780px">
      <el-form :inline="true"
               label-position="right"
               :model="formInline"
               class="demo-form-inline"
               style=""
               label-width="100px"
               :ref="'form'"
               :rules="rules">
        <el-form-item label="电子邮箱"
                      prop="email">
          <el-input :maxlength='20'
                    v-model.trim="formInline.email"
                    placeholder="请输入电子邮箱"></el-input>
        </el-form-item>
        <el-form-item label="企业类型"
                      prop="enterpriseType">
          <el-select v-model.trim="formInline.enterpriseType"
                     placeholder=""
                     size='12'>
            <el-option value=""
                       label="全部">全部</el-option>
            <el-option :value="1"
                       label="企业">企业</el-option>
            <!-- <el-option :value="2" label="机关事业单位">机关事业单位</el-option> -->
            <el-option :value="3"
                       label="个人">个人</el-option>
            <!-- <el-option :value="4" label="其他">其他</el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Invoice_header')"
                      prop="payerCompany">
          <el-input :maxlength='20'
                    v-model.trim="formInline.payerCompany"
                    placeholder="请输入发票抬头"></el-input>
        </el-form-item>
        <el-form-item label="纳税人识别号"
                      prop="nsrsbh"
                      label-width="120px"
                      style="margin-left: -20px;">
          <el-input :maxlength='20'
                    v-model.trim="formInline.nsrsbh"
                    placeholder="请输入纳税人识别号"></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.address')"
                      prop="ghfdz">
          <el-input :maxlength='20'
                    v-model.trim="formInline.ghfdz"
                    placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item label="电话"
                      prop="ghfdh">
          <el-input :maxlength='20'
                    v-model.trim="formInline.ghfdh"
                    placeholder="请输入电话"></el-input>
        </el-form-item>
        <el-form-item label="开户行"
                      prop="ghfkhh">
          <el-input :maxlength='20'
                    v-model.trim="formInline.ghfkhh"
                    placeholder="请输入开户行"></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.account_number')"
                      prop="ghfyhzh">
          <el-input :maxlength='20'
                    v-model.trim="formInline.ghfyhzh"
                    placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="发票内容"
                      prop="carId">
          <!-- <el-input :maxlength='20' v-model.trim="formInline.invoiceContent" ></el-input> -->
          <span style="width: 202px;display: block;">{{formInline.invoiceContent}}</span>
        </el-form-item>
        <el-form-item label="发票金额"
                      prop="carId">
          <!-- <el-input :maxlength='20' v-model.trim="formInline.billingFee" ></el-input> -->
          <span style="width: 202px;display: block;">{{formInline.billingFee + "元"}}</span>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="submit();page=1"
                   :loading="loading">提交</el-button>
        <el-button plain
                   @click="clearFrom()">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
} from '../../common/js/public.js'
export default {
  data () {
    return {
      dialogVisible: false,
      formInline: {
        mobile: '',
        address: '',
        enterpriseType: "",
        operationName: "",
        roadName: "",
        invoiceNum: "",
        ghfyh: "",
        email: "",
        ghfqylx: "",
        payerCompany: "",
        ghfsbh: "",
        ghfdz: "",
        ghfdh: "",
        ghfyhzh: ""
      },
      streetList: [],
      loading: false,
      rules: {
        // nsrsbh: [{
        //   required: true,
        //   message: '请选择认证结果',
        //   trigger: 'blur'
        // }, {
        //   validator: (rule, value, callback) => {
        //     if (value === '') {
        //       callback(new Error('不能为空'))
        //     } else {
        //       callback()
        //     }
        //   }
        // }],
        email: [{
          required: true,
          message: '请输入电子邮箱',
          trigger: 'blur'
        }, {
          validator: (rule, value, callback) => {
            if (!(/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/g.test(value))) {
              callback(new Error('邮箱格式不正确'));
            } else {
              callback()
            }
          },
          trigger: 'blur'
        }],
        enterpriseType: [{
          required: true,
          message: '请选择企业类型',
          trigger: 'blur'
        }, {
          validator: (rule, value, callback) => {
            if (value == '') {
              callback(new Error('企业类型不能为空'))
            } else {
              callback()
            }
          },
          trigger: 'blur'
        }],
        payerCompany: [{
          required: true,
          message: '请输入发票抬头',
          trigger: 'blur'
        }, {
          validator: (rule, value, callback) => {
            if (value == '') {
              callback(new Error('发票抬头不能为空'))
            } else {
              callback()
            }
          },
          trigger: 'blur'
        }]
        // password: [{ validator: checkPwd, trigger: "blur" }]
      }
    }
  },
  methods: {
    init (data) {
      this.dialogVisible = true;
      this.formInline = data
    },
    submit () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let opt = {
            method: 'post',
            url: '/acb/2.0/invoiceOperation/invoiceByRed',
            data: {
              invoiceNum: this.formInline.invoiceNum,
              email: this.formInline.email,
              ghfqylx: this.formInline.enterpriseType,
              payerCompany: this.formInline.payerCompany,
              ghfsbh: this.formInline.nsrsbh ? this.formInline.nsrsbh : '',
              ghfdz: this.formInline.ghfdz ? this.formInline.ghfdz : '',
              ghfdh: this.formInline.ghfdh ? this.formInline.ghfdh : '',
              ghfyhzh: this.formInline.ghfyhzh ? this.formInline.ghfyhzh : '',
              ghfyh: this.formInline.ghfkhh ? this.formInline.ghfkhh : ''
            },
            success: (res) => {
              if (res.state == 0) {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
                });
                this.dialogVisible = false;
                this.$emit('update:searchjudge')
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
                });
              }
            }
          }
          this.$request(opt);
        }
      })
    },
    clearFrom () {
      this.dialogVisible = false;
    }
  }
}
</script>
<style lang="stylus" scoped>
.reopen
  >>>.el-dialog__header
    height 40px
    background #3f4a56
    padding 0 20px
    line-height 40px
    .el-dialog__title
      line-height 40px
      color white
      font-size 14px
    .el-dialog__headerbtn
      top 13px
    .el-dialog__close
      font-size 20px
  >>>.el-dialog__body
    width 780px
  >>>.dialog-footer
    display flex
    justify-content center
</style>
