<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form
          :inline="true"
          label-position="right"
          ref="searchWrapper"
          :model="formInline"
          class="demo-form-inline"
          :rules="rules"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Invoice_number')">
                <el-input v-model.trim="formInline.fphm" placeholder="请输入发票号码"></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.mailbox')">
                <el-input v-model.trim="formInline.email" placeholder="请输入邮箱"></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId">
                <el-select v-model.trim="formInline.operationId" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Invoice_header')">
                <el-input
                  v-model.trim="formInline.payerCompany"
                  placeholder="请输入发票抬头"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Invoice_type')">
                <el-select
                  v-model.trim="formInline.invoiceType"
                  style="width: 100px"
                  placeholder=""
                  size="12"
                >
                  <el-option value="" label="请选择"></el-option>
                  <el-option value="0" label="正票"></el-option>
                  <el-option value="1" label="红票"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Invoice_time')">
                <!-- <el-date-picker v-model="date1"
                            type="datetime"
                            placeholder="选择日期"
                            @change="setStartTime"></el-date-picker> 至
            <el-date-picker v-model="date2"
                            type="datetime"
                            placeholder="选择日期"
                            @change="setEndTime"></el-date-picker> -->
                <timeRangePick
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.state')">
                <el-select v-model.trim="formInline.state" placeholder="" size="12">
                  <el-option value="" label="请选择"></el-option>
                  <el-option value="0" label="正常开票"></el-option>
                  <el-option value="3" label="红票"></el-option>
                  <el-option value="2" label="正票重开"></el-option>
                  <el-option value="1" label="正常冲红-已被重开"></el-option>
                  <el-option value="4" label="已冲红"></el-option>
                  <el-option value="5" label="自动冲红"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  searchjudge();
                  page = 1;
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <div class="tableWrapper bgFFF paddingB20">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          min-height="400"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            align="center"
            :index="indexMethod"
            width=""
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="80"
            v-if="$route.meta.authority.button.reset"
          >
            <template slot-scope="scope">
              <el-dropdown
                style="color: #20a0ff; cursor: pointer"
                :disabled="
                  scope.row.state === 1 ||
                  scope.row.state === 3 ||
                  scope.row.state === 5 ||
                  scope.row.state === 4
                    ? true
                    : false
                "
                @command="handleCommand($event, scope.row)"
              >
                <el-button
                  type="text"
                  size="small"
                  :disabled="
                    scope.row.state === 1 ||
                    scope.row.state === 3 ||
                    scope.row.state === 5 ||
                    scope.row.state === 4
                      ? true
                      : false
                  "
                  v-if="$route.meta.authority.button.reset"
                  style="padding: 0"
                >
                  操作
                  <i class="el-icon-arrow-down" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <div v-if="scope.row.state != 3">
                    <el-dropdown-item command="a" v-if="scope.row.state != 4"
                      >冲红重开</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="scope.row.state == 0 || scope.row.state == 2"
                      command="b"
                      >冲红</el-dropdown-item
                    >
                  </div>
                  <el-dropdown-item
                    v-if="scope.row.state != 4 && scope.row.state != 5 && scope.row.downloadUrl"
                    command="c"
                    >复制发票链接</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagerWrapper">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 弹窗 -->
      <reopen ref="reopen" @update:searchjudge="searchjudge"></reopen>
    </div>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import { setIndex, dateFormat } from "../../common/js/public.js";
import autoComplete from "@/components/autocomplete/myautoComponent";
import reopen from "./reopen";
// import viewdetail from './view'
export default {
  name: "invoice1",
  data() {
    return {
      defalutDate: [],
      total: 0,
      exitPic: [],
      entryExitType: ["全部", "出场", "入场"],
      pageSize: 10,
      page: 1,
      loading: false,
      berthList: [],
      tableCols: [
        {
          prop: "email",
          label: "电子邮箱",
          width: "",
        },
        {
          prop: "fpDm",
          label: "发票代码",
          width: "",
        },
        {
          prop: "fpHm",
          label: "发票号码",
          width: "",
        },
        {
          prop: "nsrmc",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "enterpriseType",
          label: "发票抬头类型",
          width: "",
          formatter: (row, column, cellValue, index) => {
            switch (cellValue) {
              case 1:
                return "企业";
              case 2:
                return "机关事业单位";
              case 3:
                return "个人";
              case 4:
                return "其他";
              default:
                "-";
                break;
            }
          },
        },
        {
          prop: "invoiceContent",
          label: "发票内容",
          width: "",
        },
        {
          prop: "payerCompany",
          label: "发票抬头",
          width: "",
        },
        {
          prop: "nsrsbh",
          label: "纳税人识别号",
          width: "",
        },
        {
          prop: "billingFee",
          label: "开票金额",
          width: "",
          formatter: (row, column, cellValue, index) => {
            if (cellValue) {
              return cellValue + "元";
            }
          },
        },
        {
          prop: "taxMoney",
          label: "税额",
          width: "",
          formatter: (row, column, cellValue, index) => {
            if (cellValue) {
              return cellValue + "元";
            }
          },
        },
        {
          prop: "taxRate",
          label: "税率",
          width: "",
        },
        {
          prop: "invoiceType",
          label: "发票类型",
          width: "50px",
          formatter: (row, column, cellValue, index) => {
            switch (cellValue) {
              case 0:
                return "正票";
              case 1:
                return "红票";
              default:
                "-";
                break;
            }
          },
        },
        {
          prop: "orderTypeName",
          label: "业务类型",
          width: "50px",
          // formatter: (row, column, cellValue, index) => {
          //   switch (cellValue) {
          //     case 0:
          //       return '停车'
          //     case 1:
          //       return '月票'
          //     default: '-'
          //       break;
          //   }
          // }
        },
        {
          prop: "state",
          label: this.$t("list.state"),
          width: "",
          formatter: (row, column, cellValue, index) => {
            switch (cellValue) {
              case 0:
                return "正常开票";
              case 1:
                return "正常冲红-已被重开";
              case 2:
                return "正票重开";
              case 3:
                return "红票";
              case 4:
                return "已冲红";
              case 5:
                return "自动冲红";
              default:
                "-";
                break;
            }
          },
        },
        {
          prop: "invoiceDate",
          label: "开票时间",
          width: "",
        },
      ],
      parkDetail: {},
      tableData: [],
      rules: {
        // account: [{ validator: checkUser, trigger: "blur" }],
        // password: [{ validator: checkPwd, trigger: "blur" }]
      },
      tenantList: [],
      formInline: {
        fphm: "",
        email: "",
        operationId: "",
        payerCompany: "",
        invoiceType: "",
        state: "",
        startDate: "",
        endDate: "",
        parkType: "",
      },
      redFlag: false,
    };
  },
  methods: {
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr) {
      this.formInline.startDate = timeArr[0];
      this.formInline.endDate = timeArr[1];
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    setStartTime(value) {
      this.formInline.startDate = dateFormat(value);
    },
    setEndTime(value) {
      this.formInline.endDate = dateFormat(value);
    },
    clearFrom() {
      this.formInline = {};
      this.$refs.autocompleteParkName.clearName();
      this.$refs.plateNo.clearName();
    },
    indexMethod(index) {
      return index + 1;
    },
    completeValue(item) {
      this.formInline.parkId = item;
    },
    streetChange(streetId) {
      this.$refs.select.setValue("");
      this.parkPage = 1;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        // this.$refs.viewdetail.init(data)
        this.$refs.reopen.init(data);
      } else if (cmd == "b") {
        // 冲红
        this.redFlag = true;
        this.postinvoiceRedOnly(data.invoiceNum);
      } else if (cmd == "c") {
        // 复制发票链接 downloadUrl
        this.copy(data.downloadUrl);
      }
    },
    // 复制
    copy(data) {
      let url = data;
      let oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: "复制成功",
        type: "success",
      });
      oInput.remove();
    },
    // 冲红
    postinvoiceRedOnly(invoiceNum) {
      let url = "/acb/2.0/invoiceOperation/invoiceRedOnly";
      this.$axios
        .post(url, { data: { invoiceNum } })
        .then((res) => {
          if (res.state == 0) {
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            this.searchjudge();
          }
        })
        .finally(() => {
          this.redFlag = false;
        });
    },
    showLog() {
      if (this.formInline.startDate && this.formInline.endDate) {
        let time = new Date(this.formInline.endDate) - new Date(this.formInline.startDate);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    searchjudge() {
      let flag = this.showLog();
      this.$refs["searchWrapper"].validate((v) => {
        if (v) {
          if (flag) {
            this.searchData();
          }
        }
      });
    },
    // 搜索
    searchData() {
      // this.showLog();
      this.loading = true;
      let opt = {
        method: "post",
        url: "/acb/2.0/invoiceSerial/query",
        data: {
          fphm: this.formInline.fphm,
          email: this.formInline.email,
          operationId: this.formInline.operationId,
          payerCompany: this.formInline.payerCompany,
          invoiceType: this.formInline.invoiceType,
          state: this.formInline.state,
          startDate: this.formInline.startDate,
          endDate: this.formInline.endDate,
          page: this.page,
          pageSize: this.pageSize,
        },
        success: (res) => {
          this.tableData = setIndex(res.value.pageNum, res.value.list);
          this.total = res.value.total * 1 || 0;
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    getListTotal() {
      let url = "/acb/2.0/daspark/getDasOption";
      this.$axios.get(url).then((res) => {
        this.listTotal = res.value;
      });
    },
  },
  components: {
    reopen,
    timeRangePick,
  },
  created() {
    // this.getAreaList();
    this.getTenantList();
    this.defalutDate = [this.dataTimeRest() + " 00:00:00", this.dataTimeRest() + " 23:59:59"];
  },
  mounted() {
    // this.getListTotal()
    // this.searchData()
    this.searchData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.conborder
  border-top 1px solid #C0CCDA
  background #FFFFFF
  overflow hidden
  padding 10px
.content
  .echartsBox
    width 100%
    .lineEcharts
      width 60%
      height 200px
      float left
    .barEcharts
      width 40%
      height 200px
      float left
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      border 1px solid #0099FA
      border-radius 4px
      color #0099FA
      padding 8px 13px
      cursor pointer
      .iconfont
        margin-right 14px
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
</style>
